// brand
$persimmon-base: #f0553a;
$persimmon-darker: #d63625;
$persimmon-darkest: #bc2111;

$cobalt-lighter: rgba(58, 65, 76, 0.25);
$cobalt-light: rgba(58, 65, 76, 0.5);
$cobalt-base: #3a414c;
$cobalt-darker: #212730;
$cobalt-darkest: #0d1116;

// interaction
$teal-base: #0088a9;
$teal-darker: #00738e;
$teal-darkest: #005c75;

$dark-teal-base: #006f8a;
$dark-teal-darker: #005b70;
$dark-teal-darkest: #005b70;

$sky-base: #d4ebf2;
$sky-darker: #b0d9e6;
$sky-darkest: #92c8da;

$granite-base: #4f5d75;
$granite-darker: #354259;
$granite-darkest: #212c3f;

// tonal
$coal: #333943;
$shale: #697582;
$slate: #8090a2;
$alloy: #c4cdd6;
$platinum: #e1e6eb;
$silver: #eff3f5;
$horizon: #edf3f8;
$white: #ffffff;

// bootstrap alert types
$success-base: #35ad96;
$success-darker: #22917e;
$success-darkest: #127769;

$info-base: #41b5dc;
$info-darker: #2b9dc1;
$info-darkest: #188aa8;

$warning-base: #ffa23f;
$warning-darker: #e58628;
$warning-darkest: #cc7712;

$danger-base: #d24a35;
$danger-darker: #b73721;
$danger-darkest: #9e2810;

// link colors
$link: #0088a9;
$link-visited: #00738e;
$link-hover: #0088a9;

$defaultChartColors:(
  //#d7f0f9, the light-blue may too bright and provide low contrast with the white background
  #92d5f0,
  #43b6dd,
  #35adb8,
  #59ac82,
  #83ac70,
  #fea23f,
  #ff823a,
  #fc5e3a,
  #cd4e3e,
  #864a4a,
  #53464C
);

:export {
  defaultChartColors: $defaultChartColors;
}

