.action-bar h2 {
  margin-top: 9px;
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 9px !important;
  margin-bottom: 9px;
}

.rdt {
  width: 100% !important;
}

.datetime {
  display: inherit !important;
}

.navbar .navbar-header h2 {
  line-height: 30px !important;
}
