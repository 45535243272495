.campaign-details-page {
  background-color: white;
  border-radius: 5px;
  border: 1px solid $alloy
}

.campaign-info-pane {
  padding: 0 10px 10px;
  height: 100%;
  background-color: $sky-base;
  border-radius: 5px 0 0 5px;

  -webkit-box-shadow: 1px 0px 1px 0px $shale;
  -moz-box-shadow: 1px 0px 1px 0px $shale;
  box-shadow: 1px 0px 1px 0px $shale;
}

.campaign-questions-pane {
  padding: 10px;
  padding-left: 0;
  margin-left: -20px;
}

.row.display-flex {
  display: flex;
}

.row.display-flex>[class*='col-'] {
  display: flex;
  flex-direction: column;
}

.actionBar {
  border-radius: 0;
  border-bottom: 2px solid $white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -9px;
  margin-right: -9px;
  background-color: white;
}

.flexContainer.actionBar {
  justify-content: space-around;
  align-items: stretch;
}

.flexContainer.actionBar .flexChild {
  text-align: center;
}

.flexContainer.actionBar .flexChild:hover {
  border-radius: 5px;
}

.flexContainer.actionBar svg:hover {
  border-radius: 5px;
}

.question-title {
  border-bottom: 1px solid $sky-darkest;
  padding-bottom: 10px;
}

.question-container {
  margin-top: 5px;
  background-color: $white;
  padding: 20px;
  padding-top: 0;
  border: 0 solid $alloy;
  border-top: 2px solid $horizon;
}

.question-container:hover {
  background-color: $horizon;
}

.question-container h6 {
  margin-top: 20px;
}

.accordion-action-list {
  position: relative;
  float: right;
  margin: 5px;
  margin-right: -15px;
  border-radius: 7px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
}

.campaign-dates-info {
  color: $shale;
}

.info-group {
  margin-bottom: 20px;
}
