@import "../../styles/mcpColors.module";

.result-container {
  padding: 10px;

  margin-bottom: 10px;
  border-bottom: 1px solid $alloy;
}

.result-container-contents {
  padding: 10px;
}
