@import "./mcp.overrides";
@import "../styles/mcpColors.module";
@import "../components/campaignDetails/campaignInfo";

.flexContainer {
  display: flex;
}

.flexChild {
  flex-grow: 1;
}

.flexChildx3 {
  flex-grow: 3;
}
.flexChildx5 {
  flex-grow: 5;
}

.flexChildGrow {
  flex-grow: 1000;
}

.main {
  padding: 10px;
}


.action-bar {
  text-align: right;
  padding-bottom: 10px;
}

.icon-button svg {
  cursor: pointer;
  padding: 5px;
}

.icon-button svg:hover {
  padding: 5px;
  background-color: $horizon;
}

.code-block {
  margin-bottom: 30px;
}

.code-copy-button {
  margin-bottom: -25px;
  margin-right: 10px;
  text-align: right;
}

@keyframes example {
  0%   {opacity: 0;}
  100%  {opacity: 1;}
}
.code-copy-button svg {
  animation-name: example;
  animation-duration: 1s;
}
