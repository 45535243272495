@import "../../styles/mcpColors.module";

.modal-body {
  overflow-y: visible !important;
}

.settingsVersion {
  color: $alloy;
  font-style: italic;
}
